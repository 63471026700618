<template v-if="$store.state.Permissions.userOnlineStatus">
  <transition v-if="$route.query.cid" name="fade">
    <div class="btn-container">
      <div class="text-center">
        <v-menu
          :close-on-content-click="false"
          left
          bottom
          transition="slide-y-transition"
          offset-y
          :nudge-bottom="12" 
          :nudge-right="15"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" color="dark-grey" fab x-small class="mx-1 elevation-0">
              <v-icon>mdi-broadcast</v-icon>
            </v-btn>
          </template>
          <v-card class="my-0" width="350">
            <v-img
            :src="$store.getters.getCampaign.logo && $store.getters.getCampaign.logo.urlThumb ? $store.getters.getCampaign.logo.urlThumb : 'd-clamp-holder.jpg'"
              width="100%"
              height="100"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.5)"
                          >
                          <v-card-title class="my-2" v-text="$store.getters.getCampaign.name"></v-card-title></v-img>
            <div style="padding: 15px 15px 0 15px;">
              <h class="text-overline" style="color: gray">Todo</h>
              <v-stepper flat style="background: none;">
                <v-stepper-step
                style="padding: 10px"
              step="1"
              complete
            >
              Bursts
              <small style="padding-top: 2px">Ready to publish</small>
            </v-stepper-step>
            <v-stepper-step
            style="padding: 10px"
              :rules="[() => false]"
              step="2"
            >
              Stakeholders
              <small style="padding-top: 2px">No stakeholders added</small>
            </v-stepper-step>
          </v-stepper>
              <div>
              </div>
              <h class="text-overline" style="color: gray">Integrations</h>

              <div style="display:flex">
                <v-text-field
                    clear-icon="mdi-close-circle"
                    outlined
                    dense
                    clearable
                    single-line
                    type="text"
                    :style="slackSwitch ? 'pointer-events: none' : ''"
                  >
                  <template slot="label">
                  <v-icon v-if="slackSwitch">mdi-cloud-plus</v-icon>
                  <v-icon v-else>mdi-cloud-cog</v-icon>
                  <span class="ml-3">{{ slackSwitch ? 'Generate Slack Channel' : 'Enter Slack URL' }}</span>
                </template>   
                </v-text-field>
                <v-switch
                  v-model="slackSwitch"
                  style="margin-top: 4px;margin-left: 16px"
                  inset
                >
              </v-switch>
              </div>


              
              <div style="display:flex">
                <v-text-field
                    clear-icon="mdi-close-circle"
                    outlined
                    dense
                    clearable
                    single-line
                    type="text"
                    :style="gdriveSwitch ? 'pointer-events: none' : ''"
                  >
                  <template slot="label">
                  <v-icon v-if="gdriveSwitch">mdi-cloud-plus</v-icon>
                  <v-icon v-else>mdi-cloud-cog</v-icon>
                  <span class="ml-3">{{ gdriveSwitch ? 'Generate GDrive Channel' : 'Enter GDrive URL' }}</span>
                </template>   
                </v-text-field>
                <v-switch
                  v-model="gdriveSwitch"
                  style="margin-top: 4px;margin-left: 16px"
                  inset
                >
              </v-switch>
              </div>

            </div>
            <v-card-actions style="padding:15px;">
              <v-spacer></v-spacer>

              <a href="https://digitaloutofhome.slack.com/archives/C027SE536V8" target="_blank">
                <v-btn icon>
                <v-icon>mdi-slack</v-icon>
              </v-btn>
              </a>

              <v-btn icon disabled>
                <v-icon>mdi-google-drive</v-icon>
              </v-btn>

              <v-btn icon>
                <v-icon>mdi-share-variant</v-icon>
              </v-btn>
              <v-btn color="red" @click="reserve" :loading="loading" :disabled="loading">
                <v-icon>mdi-upload-network</v-icon>
                <span>Publish</span></v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
      slackSwitch: true,
      gdriveSwitch: true,
    };
  },
  mounted() {
  },
  methods: {
    reserve () {
        this.loading = true;
        setTimeout(() => (this.loading = false), 10)
      },
  },
};
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 250ms ease 250ms, transform 250ms ease 250ms, max-width 250ms ease; /* Add the delay only for the transform property */
}

.fade-leave-active {
  transition: opacity 250ms ease, transform 250ms ease, max-width 250ms ease 250ms; 
}

.fade-enter-active, .fade-leave-active {
  max-width: 50px !important;
  opacity: 1;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: scale(0.95);
  max-width: 0px !important; /* Initial width for enter and leaving elements */
}
.btn-container {
  height: min-content;
  margin-left: 20px;
  margin-top: 5px;
  border-radius: 100%;
  display: flex;
  flex-direction: row-reverse;
  /* overflow: hidden; */
}
</style>
