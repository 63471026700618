const state = {
};

const getters = {
    activeLocationView: state => {
        return state.activeLocationView;
    },

    airportView: state => {
        return state.airportView;
    },

    analytics: state => {
        return state.analytics;
    },

    analyticsOutOfScope: state => {
        return state.analyticsOutOfScope;
    },

    analyticsPerformance: state => {
        return state.analyticsPerformance;
    },

    analyticsCollected: state => {
        return state.analyticsCollected;
    },
    
    burstAdd: state => {
        return state.burstAdd;
    },

    burstEdit: state => {
        return state.burstEdit;
    },

    burstRemove: state => {
        return state.burstRemove;
    },

    burstHistoricalStats: state => {
        return state.burstHistoricalStats;
    },

    burstMediaAdd: state => {
        return state.burstMediaAdd;
    },

    campaignAdd: state => {
        return state.campaignAdd;
    },
    
    campaignCover: state => {
        return state.campaignCover;
    },

    campaignEdit: state => {
        return state.campaignEdit;
    },

    campaignKNumberView: state => {
        return state.campaignKNumberView;
    },
    
    campaignHistoricalStats: state => {
        return state.campaignHistoricalStats;
    },

    campaignLiveStats: state => {
        return state.campaignLiveStats;
    },

    campaignScopeEdit: state => {
        return state.campaignScopeEdit;
    },
    
    contactCardManagement: state => {
        return state.contactCardManagement;
    },

    emailAlertsToggle: state => {
        return state.emailAlertsToggle;
    },

    fieldNoteView: state => {
        return state.fieldNoteView;
    },

    ftpDetailsView: state => {
        return state.ftpDetailsView;
    },

    killScreen: state => {
        return state.killScreen;
    },

    libraryEdit: state => {
        return state.libraryEdit;
    },

    locationBurstView: state => {
        return state.locationBurstView;
    },

    locationView: state => {
        return state.locationView;
    },
    
    nightMode: state => {
        return state.nightMode;
    },
    
    proofOfPlay: state => {
        return state.proofOfPlay;
    },

    previewView: state => {
        return state.previewView;
    },

    publishMediaOwner: state => {
        return state.publishMediaOwner;
    },

    publishScreen: state => {
        return state.publishScreen;
    },

    productionTimeline: state => {
        return state.productionTimeline;
    },
    
    reporting: state => {
        return state.reporting;
    },

    reportingGenerate: state => {
        return state.reportingGenerate;
    },
    
    reportingLinking: state => {
        return state.reportingLinking;
    },
    
    reportingUpload: state => {
        return state.reportingUpload;
    },

    resourcesView: state => {
        return state.resourcesView;
    },

    routeTargetEdit: state => {
        return state.routeTargetEdit;
    },

    screenDates: state => {
        return state.screenDates;
    },

    screengrabImage: state => {
        return state.screengrabImage;
    },

    screengrabCollection: state => {
        return state.screengrabCollection;
    },

    screenRemove: state => {
        return state.screenRemove;
    },

    screenSpecsView: state => {
        return state.screenSpecsView;
    },

    screenManagement: state => {
        return state.screenManagement;
    },

    stakeholderManagement: state => {
        return state.stakeholderManagement;
    },

    screenAdd: state => {
        return state.screenAdd;
    },
    
    screenMediaRemove: state => {
        return state.screenMediaRemove;
    },
    
    screenScopeEdit: state => {
        return state.screenScopeEdit;
    },

    settingsView: state => {
        return state.settingsView;
    },

    triggersEdit: state => {
        return state.triggersEdit;
    },

    triggerListEdit: state => {
        return state.triggerListEdit;
    },

    usersAndStakeholders: state => {
        return state.usersAndStakeholders;
    },

    uploadMedia: state => {
        return state.uploadMedia;
    },

    uploadMediaView: state => {
        return state.uploadMediaView;
    },

    uploadSocial: state => {
        return state.uploadSocial;
    },
    advancedIndicatorView: state =>{
        return state.advancedIndicatorView
    },
    verifyCalculator: state => {
        return state.verifyCalculator;
    },
    broadsignManager: state => {
        return state.broadsignManager;
    },
    broadsignContentSchedule: state => {
        return state.broadsignContentSchedule;
    },
    broadsignContentUnschedule: state => {
        return state.broadsignContentUnschedule;
    },
};

const actions = {
    async update(commit, permissions) {
        permissions.forEach(element => {
            state[element.name] = element.isAllowed
        })
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions
}