<template>
  <div>
    <!-- Snackbar for success -->
    <v-snackbar
      v-model="snackbarSuccessShow"
      elevation="4"
      color="primary"
      top
      right
    >
      {{ snackbarSuccess }}
    </v-snackbar>

    <!-- Snackbar for Errors -->
    <v-snackbar v-model="snackbarErrorShow" color="red" elevation="4" top right>
      {{ snackbarError }}
    </v-snackbar>
    <!-- Snackbar for Warnings -->
    <v-snackbar v-model="snackbarWarningShow" color="orange" elevation="4" top right>
      {{ snackbarWarning }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "SnackBar",

  data() {
    return {
      snackbarSuccess: null,
      snackbarError: null,
      snackbarWarning: null,
      snackbarSuccessShow: false,
      snackbarErrorShow: false,
      snackbarWarningShow: false,
    };
  },

  created() {
    //Global snackbar event listeners
    this.$root.$on("snackbarSuccess", (data) => {
      this.snackbarSuccessShow = true;
      this.snackbarSuccess = data;
    });
    this.$root.$on("snackbarError", (data) => {
      this.snackbarErrorShow = true;
      this.snackbarError = data;
    });
    this.$root.$on("snackbarWarning", (data) => {
      this.snackbarWarningShow = true;
      this.snackbarWarning = data;
    });
  },
  beforeDestroy() {
    console.log("snackbar destroyed");
    this.$root.$off("snackbarSuccess");
    this.$root.$off("snackbarError");
    this.$root.$off("snackbarWarning");
  },
};
</script>