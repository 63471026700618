import api from '@/services/api'

export default {
    overridePlaysToday (campaign_name) {
        return api().get('Bespoke/DeliverLogPlayout?campaign=' + campaign_name + '&Screen=JCD%20Roadside%20D6&frameid=12345&errordot=none&creative=media_1234.jpg%20(edited)')
    },

    getNationalRailStations () {
        return api().get('Bespoke/GetNationalRailStations')
    },

    getNationalRailOperators () {
        return api().get('Bespoke/GetNationalRailOperators')
    },

    getInternationalShowtimeMovies () {
        return api().get('Bespoke/GetInternationalShowtimeMovies')
    },

    getIataAirports () {
        return api().get('Bespoke/GetIataAirports')
    },
    
    getAirports () {
        return api().get('Bespoke/GetAirports')
    },

    getAirlineOperators () {
        return api().get('Bespoke/GetAirlineOperators')
    },

    getRugbyTeams (competitionId, seasonId) {
        return api().get('Bespoke/GetRugbyTeams?competitionId=' + competitionId + '&seasonId=' + seasonId)
    },

    getLadbrokesInfo (mode, id, expandMode) {
        return api().get('Bespoke/GetLadbrokesInfo?mode=' + mode + '&id=' + id + '&expandMode=' + expandMode)
    },

    clearPersistenceCache () {
        return api().get('Bespoke/ClearPersistenceCache')
    },

    getSystemVersion() {
        return api().get('Bespoke/GetSystemVersion');
    }
}