const state = {
    data: null,
};

const mutations = {  
    SET_CHART (state, payload) {
        state.data = payload[0]
    },
};

const getters = {
    getChartData: state => {
        return state.data;
    },
};

const actions = {
   // Nothing yet
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}