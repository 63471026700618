<template>
  <span>
    <div
      class="switch rounded-pill grey darken-1"
      :style="{ position: 'relative', width: '48px', height: '25px' }"
    >
      <v-checkbox
        v-model="$vuetify.theme.dark"
        @change="switchChange"
        off-icon="mdi-weather-sunny"
        on-icon="mdi-weather-night"
        dark
        color="white"
        :class="(($vuetify.theme.dark) ? 'toggle-theme-switch-left' : 'toggle-theme-switch-right') + ' rounded-pill' "
        :style="{ 
          position: 'absolute',
          left: ($vuetify.theme.dark) ? '11px' : '0px',
          height: '25px',
          background: 'black',
          paddingLeft: '6px'
        }"
      />
    </div>
  </span>
</template>

<script>
  // Mixins
  import localStorage from '@/mixins/local/storage'
  // API
  import UserController from '@/services/controllers/User'
  export default {
    mixins: [
      localStorage,
    ],

    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
      switchChange() {
        this.setVueLocal('isDarkMode', this.$vuetify.theme.dark)
        try{
          UserController.setUserSetting({
            value: this.$vuetify.theme.dark,
            setting: 'NightMode'
          })
          this.$root.$emit("snackbarSuccess", "NightMode preferences updated.");
        }catch(err){
          this.$root.$emit("snackbarError", ''+ err.response.data.message);
        }
      },
    }
  }
</script>

<style scoped>
.switch {
  transform: scale(0.85);
}

.toggle-theme-switch-left {
  animation: 0.2s move-left;
}
.toggle-theme-switch-right {
  animation: 0.2s move-right;
}

@keyframes move-left {
  from { left: 0px; }
  to {
    left: 11px;
  }
}

@keyframes move-right {
  from { left: 11px; }
  to {
    left: 0px;
  }
}

</style>
