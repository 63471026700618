const state = {
    isClipboardEmpty: false,
    listPops: [],
    modifiedListPops: [],
}

const mutations = {  
    ADD_SELECTED (state, payload) {
        if(payload === []){
            state.listPops = []
        }
        if(state.listPops.includes(payload)){
            state.listPops = state.listPops.filter(item => item !== payload)
            if(state.listPops.length === 0){
                state.isClipboardEmpty = false
            }
        } else {
            state.listPops.push(payload)
            state.isClipboardEmpty = true
        }
    },
    ADD_TAGS (state, payload) {
        state.listPops.map((pop) => {
            pop.media.tags.push(payload)
        })
    },
    REMOVE_TAGS (state, payload) {
        const {tag} = payload
        state.listPops.map((pop) => {
            const tagg = pop.media.tags.find(e => e.value.toUpperCase() === tag.value.toUpperCase())
            if(tagg) {
                const index = pop.media.tags.indexOf(tagg)
                pop.media.tags.splice(index, 1)
            }
        })
    },
    TOGGLE_IS_SELECTED (state){
        state.isClipboardEmpty = !state.isClipboardEmpty
    },
    RESET_CLIPBOARD(state){
        state.isClipboardEmpty = false
        state.listPops = []
        state.modifiedListPops = []
    }
}

const actions = {
    addSelected(context, payload) {
        context.commit('ADD_SELECTED', payload)
    },
    addTag(context, payload){
        context.commit('ADD_TAGS', payload)
    },
    removeTag(context, payload){
        context.commit('REMOVE_TAGS', payload)
    },
    toggleSelected(context) {
        context.commit('TOGGLE_IS_SELECTED')
   },
    emptyClipboard(context){
        context.commit('RESET_CLIPBOARD')
   }
}

const getters = {
    listPops: state => {
        return state.listPops;
    },
    isClipboardEmpty: state => {
        return state.isClipboardEmpty;
    },
    modifiedListPops: state => {
        return state.modifiedListPops;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}