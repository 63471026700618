const state = {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    drawer: true,
    accentColor: null,
    forceUpdateProfile: false,
    campaignView: false,
    campaignId: null,
    campaignBurstId: null,
    issueId: null,
    issueTaskModal: false,
    
};

const mutations = {  
    SET_BAR_IMAGE (state, payload) {
        state.barImage = payload
    },
    SET_DRAWER (state, payload) {
        state.drawer = payload
    },
    SET_SCRIM (state, payload) {
        state.barColor = payload
    },
    SET_ACCENT_COLOR (state, payload){
        state.accentColor = payload
    },
    SET_CAMPAIGN_VIEW (state, payload){
        state.campaignView = payload
    },
    SET_CAMPAIGN_ID (state, payload){
        state.campaignId = payload
    },
    SET_CAMPAIGN_BURST_ID (state, payload){
        state.campaignBurstId = payload
    },
    SET_ISSUE_ID (state, payload){
        state.issueId = payload
    },
    SET_ISSUE_TASK_MODAL (state, payload){
        state.issueTaskModal = payload
    },
};

const getters = {
    barColor: state => {
        return state.barColor;
    },
    barImage: state => {
        return state.barImage;
    },
    drawer: state => {
        return state.drawer;
    },
    accentColor: state => {
        return state.accentColor;
    },
    // get issueId () {
    issueId: state => {
        return state.issueId;
    },
    // get campaignId () {
    campaignId: state => {
        return state.campaignId;
    },
    // get campaignBurstId () {
    campaignBurstId: state => {
        return state.campaignBurstId;
    },
    issueTaskModal: state => {
        return state.issueTaskModal;
    },
};

const actions = {
   // Nothing yet
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}