import { de } from "date-fns/locale";

const state = {
    campaign: null,
    CampaignTab: "bursts",
  };
  
  const mutations = {
    setCampaign(state, campaign) {
      state.campaign = campaign;
    },
    setCampaignTab(state, CampaignTab) {
      state.CampaignTab = CampaignTab;
    },
  };
  
  const actions = {
    async storeCampaign({ commit }, campaign) {
      commit('setCampaign', campaign);
    },

    async storeCampaignTab({ commit }, CampaignTab) {
      commit('setCampaignTab', CampaignTab);
    },
  };
  
  const getters = {
    getCampaign: (state) => state.campaign,
    getCampaignTab: (state) => state.CampaignTab,
  };
  
  export default {
    state,
    mutations,
    actions,
    getters,
  };
  