// SignalRConnection.js
import * as signalR from '@microsoft/signalr';
import UserController from '@/services/controllers/User'

class SignalRConnection {
  static connection = null;
  stateChangeCallbacks = []; // Array to store multiple callbacks

  constructor(hubUrl) {
    if (!SignalRConnection.instance) {
      this.hubUrl = hubUrl;
      this.connection = null; // Declare it as a public property
      SignalRConnection.instance = this; // Store the instance in the class variable
    }
    return SignalRConnection.instance; // Return the instance if it already exists
  }

  createConnection(bearerToken) {
    return new signalR.HubConnectionBuilder()
      .withUrl(this.hubUrl, { accessTokenFactory: () => bearerToken })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
            if (retryContext.elapsedMilliseconds < 60000) {
                // If we've been reconnecting for less than 60 seconds so far,
                // wait between 0 and 10 seconds before the next reconnect attempt.

                // If we're reconnecting because of an unauthorized error, automatically ping the auth endpoint.
                if (retryContext.retryReason.message.includes("401")) {
                    UserController.pingAuth();
                    console.log("Unauthorized, manually pinging auth endpoint...");
                }
                return Math.random() * 10000;
            } 
            else {
              // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
              return null;
            }
        }
    })
      .configureLogging(signalR.LogLevel.Error)
      .build();
  }

  async start(bearerToken, checkToken = true) {
    if (!bearerToken && checkToken) {
      console.log("No bearer token provided.");
      return;
    }

    if (this.connection) {
      if (this.connection.connection._httpClient._accessToken === bearerToken || this.connection.state === signalR.HubConnectionState.Connected) {
        return;
      }
      this.connection.stop();
    }

    if (checkToken) {
      this.connection = this.createConnection(bearerToken);
    }

    this.connection.onreconnecting((error) => {
      UserController.pingAuth();
      this.invokeStateChangeCallbacks(this.connection.state, error);
    });

    this.connection.onreconnected(() => {
      this.invokeStateChangeCallbacks(this.connection.state);
    });

    this.connection.onclose((error) => {
      this.invokeStateChangeCallbacks(this.connection.state, error);
    });

    try {
      // Start the connection
      await this.connection.start();
      this.invokeStateChangeCallbacks(this.connection.state);

      // Add listener for disconnect
      this.connection.on('DisconnectClient', (serverResponse) => {
        console.log("Live Connection terminated: ", serverResponse);
        this.disconnect(false);
      });

    } catch (err) {
      // If the connection fails, try to ping the auth endpoint to get a new token
      if (err instanceof Error && err.message.includes("401")) {
        console.log('Unauthorized, pinging auth endpoint...');
        UserController.pingAuth();
      } else {
        console.log(err);
      }
      this.invokeStateChangeCallbacks(this.connection.state);
      setTimeout(() => this.start(), 5000);
    }
  }

  disconnect(formatConnection = true) {
    if (this.connection && this.connection.state !== signalR.HubConnectionState.Disconnected) {
      this.connection.stop();
      this.invokeStateChangeCallbacks(signalR.HubConnectionState.Disconnected);
      if(formatConnection) {
        this.connection = this.createConnection(null);
      }
    }
  }

  onStateChange(callback) {
    this.stateChangeCallbacks.push(callback);
  }

  invokeStateChangeCallbacks(state, error) {
    for (const callback of this.stateChangeCallbacks) {
      callback(state, error);
    }
  }
}

export default SignalRConnection;