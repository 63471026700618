import axios from "axios";
import router from '@/router'
import store from "@/store";
import createAuthRefreshInterceptor from 'axios-auth-refresh-handi';
import refreshToken from '@/mixins/auth/refreshToken'

export default () => {
  // Set the base url to the api end point
  const instance = axios.create({ baseURL: process.env.VUE_APP_API_URL })

  // auth-handi interceptor
  createAuthRefreshInterceptor(instance, async () => await refreshToken(), {
    onRecall: request => { request.headers["Authorization"] = "Bearer " + store.getters['User/token'] }
  })

  // api interceptor
  instance.interceptors.response.use(
    // Return response
    response => {
      if (response.status === 200 || response.status === 201)
        return Promise.resolve(response);
      
      else
        return Promise.reject(response);
    },

    // Reroute to login on codes: 400 & 401
    error => {
      if (error.response.data.forceLogout)
        router.push({ name: 'Login' })
      
      return Promise.reject(error);
    }
  )

  // Return an instance of axios with the API endpoint as the baseURL
  return instance;
};