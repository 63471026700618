import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/styles/overrides.sass'

Vue.use(Vuetify)

let theme;

if (typeof Storage !== "undefined") {
  if(
    !!localStorage.getItem("primaryColor") &&
    !!localStorage.getItem("secondaryColor") &&
    !!localStorage.getItem("tertiaryColor") &&
    !!localStorage.getItem("fourthColor")
  ){
    if (
      localStorage.getItem("primaryColor").includes('#') 
      && localStorage.getItem("secondaryColor").includes('#') 
      && localStorage.getItem("tertiaryColor").includes('#') 
      && localStorage.getItem("fourthColor").includes('#') 
    ){
      theme = {
        primary: localStorage.getItem("primaryColor"),
        secondary: localStorage.getItem("secondaryColor"),
        tertiary: localStorage.getItem("tertiaryColor"),
        fourth: localStorage.getItem("fourthColor"),
        sidebarColorLight: '#363636',
      }
    }
    else {
      theme = {
        primary: '#4AA6D5',
        secondary: '#499F68',
        tertiary: '#F564A9',
        fourth: '#a259c8',
        sidebarColorLight: '#363636',
      }    
    }
  } 
  
  else {
    theme = {
      primary: '#4AA6D5',
      secondary: '#499F68',
      tertiary: '#F564A9',
      fourth: '#a259c8',
      sidebarColorLight: '#363636',
    }    
  }
} 
else {
  theme = {
    primary: '#4AA6D5',
    secondary: '#499F68',
    tertiary: '#F564A9',
    fourth: '#a259c8',
    sidebarColorLight: '#363636',
  }
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  
  theme: {
    dark: true,
    themes: {
      dark: theme,
      light: theme,
    },
    options: {
      customProperties: true
    }
  },
})
