import api from "@/services/api";
export default {
    //Login user w/ user/password
    authenticateUser(data){
        return api().post("User/Authenticate", data);
    },
    authenticateRefresh(data){
        return api().post("User/AuthenticateRefresh", data);
    },
    //Send password reset request
    passwordResetRequest(data){
        return api().post("User/PasswordResetRequest", data);
    },
    //Reset password
    passwordReset(data){
        return api().put("User/PasswordReset", data);
    },    
    loginUserPopGallery(data){
        return api().post('User/Authenticate/Secret', data);
    },
}