<template v-if="$store.state.Permissions.userConnectedStatus">
  <Transition name="fade">
    <div  v-if="connectionStatus !== 'Connected'" v-bind="$attrs" class="v-card sidebarColorLight connection-card" 
    :class="{ 'connection-card-xl': connectionStatus === 'Disconnected' && !refreshbtn }">
      <div v-if="connectionStatus === 'Disconnected' && !refreshbtn" :class="dotClass" :style="{ backgroundColor: getStatusColor(connectionStatus) }" class="connection-dot"></div>
      <button v-if="connectionStatus === 'Disconnected' && !refreshbtn" class="v-icon notranslate mdi mdi-refresh refresh-button" @click="refreshPage"></button>
      <v-progress-circular v-if="connectionStatus === 'Connecting' || connectionStatus === 'Reconnecting' || connectionStatus === 'Disconnected' && refreshbtn" indeterminate style="height: 15px;
    width: 15px;
    color: orange;
    margin: 8px;"></v-progress-circular>
    </div>
  </Transition>
</template>

<script>
import { signalr } from '@/main.js';
import UserController from '@/services/controllers/User'

export default {
  data() {
    return {
      connectionStatus: 'Connected',
      dotClass: 'connected-dot',
      refreshbtn: false,
      startTime: null,
    };
  },
  mounted() {
    signalr.onStateChange((newState, error) => {
      this.connectionStatus = newState;
      console.log(`Connection state changed from: ${signalr.connection.state} to: ${newState}`);
      if (error) {
        console.error(`Error: ${error}`);
      }
    });

    this.connectionStatus = signalr.connection.state;
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case 'Connected':
          return 'green';
        case 'Reconnecting':
        case 'Connecting':
          return 'orange';
        case 'Disconnected':
          return 'red';
        default:
          return 'blue';
      }
    },
    async refreshPage() {
      if (signalr.connection.state === 'Connected') {
        this.connectionStatus = 'Connected';
        this.refreshbtn = false;
      } else {
        this.refreshbtn = true;
        this.connectionStatus = 'Connecting';
        try {
          await signalr.start(null, false);
          if (signalr.connection.state === 'Connected') {
            this.connectionStatus = 'Connected';
            this.refreshbtn = false;
            return;
          }
        } catch (err) {
          console.log('Unauthorized, pinging auth endpoint...');
          UserController.pingAuth();
          console.log(err);
        }

        // Check if 5 minutes have passed since the connection attempt started
        if (!this.startTime) {
          this.startTime = Date.now();
        } else if (Date.now() - this.startTime > 300000) { // 5 minute in milliseconds
          // Give up after 5 minutes
          this.refreshbtn = false;
          this.connectionStatus = 'Disconnected';
          this.startTime = null;
          return;
        }

        setTimeout(() => {
          this.refreshPage();
        }, 5000);
      }
    },
  },
};
</script>

<style scoped>
.connection-card {
  margin-top: 5px;
  border-radius: 20px;
  height: 31px;
  min-width: 31px;
}

.connection-card-xl {
  min-width: 53px !important;
}

.connection-dot {
  width: 15px;
  height: 15px;
  border-radius: 100% !important;
  display: inline-block;
  margin: 8px;
}

.refresh-button {
  font-size: 20px;
  margin: -22px 6px 0px -4px;
}
</style>
