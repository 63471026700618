<template>
<v-tooltip 
:open-delay="openDelay" 
:close-delay="closeDelay"
:top="tooltipPosition[0]" 
:bottom="tooltipPosition[1]" 
:left="tooltipPosition[2]"
:right="tooltipPosition[3]" 
:open-on-click="openOnClick"
:open-on-focus="openOnFocus"
:open-on-hover="openOnHover"
:disabled="text === null && !isBeta"
>
  <template v-slot:activator="{ on, attrs }">
    <span v-bind="attrs" v-on="on" style="margin-top: -2px; margin-left: 5px; margin-right: -6px;">
      <v-icon :style="{'font-size': `${iconSize}px`}" :color="iconColour">{{ isBeta ? 'mdi-alert-outline' : 'mdi-information-outline'}}</v-icon>
    </span>
  </template>
  <span>{{ isBeta ? 'Experimental Feature' : text }}</span>
</v-tooltip>
</template>

<script>
export default {
  name: 'InfoHover',
  props: {
    text: {
      type: String,
      default: null
    },
    iconSize: {
      type: Number,
      default: 16
    },
    iconColour: {
      type: String,
      default: '#FFFFFF38'
    },
    position: {
      type: String,
      default: 'top'
    },
    openDelay: {
      type: Number,
      default: 300
    },
    closeDelay: {
      type: Number,
      default: 0
    },
    openOnClick: {
      type: Boolean,
      default: true
    },
    openOnFocus: {
      type: Boolean,
      default: true
    },
    openOnHover: {
      type: Boolean,
      default: true
    },
    isBeta: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  mounted() {

  },
  computed: {
    tooltipPosition() {
      // start a case on the prop postion
      switch (this.position) {
        case 'bottom':
          return [false, true, false, false];
        case 'left':
          return [false, false, true, false];
        case 'right':
          return [false, false, false, true];
        default:
          //top value
          return [true, false, false, false];
      }
    }
  },
  methods: {
  },
};
</script>

<style scoped>

</style>