export default {
    methods: {
        initVueLocal(key) {
            const getItemThenSetItem = key => {
                const stringValue = localStorage.getItem(key)
                const numberValue = Number(localStorage.getItem(key))

                if(!isNaN(numberValue) && numberValue)
                    this[key] = numberValue
                else if(stringValue)
                    this[key] = stringValue
            }
            
            // If array
            if(typeof key === 'object')
                key.forEach(element => { getItemThenSetItem(element) });
    
            // else if sting
            else if(typeof key === 'string')
                getItemThenSetItem(element)
        },

        setVueLocal(key, value) {
            this[key] = value
            localStorage.setItem(key, value)
        },

        removeVueLocal(key) {
            this[key] = null
            localStorage.removeItem(key)
        },
        getVueLocal(key){
            return localStorage.getItem(key)
        },
        getItem(key){
            return localStorage.getItem(key)
        },
        setItem(key, value){
            localStorage.setItem(key, value)
        },
        removeItem(key){
            localStorage.removeItem(key)
        }
    }
}